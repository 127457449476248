<template>
  <FormKit
    type="form"
    id="salons"
    :actions="false"
    v-model="initValue"
    :classes="{ messages: 'tw-text-red-500 tw-text-center tw-mt-4' }"
    @submit="submit"
  >
    <FormKit
      name="name"
      type="text"
      label="Nama Salon"
      validation="required"
      validation-visibility="dirty"
      :classes="defaultFormikListClass"
    />

    <FormKit
      name="description"
      type="text"
      label="Deskripsi"
      validation="required"
      validation-visibility="dirty"
      :classes="defaultFormikListClass"
    />

    <FormKit
      name="address"
      type="text"
      label="Alamat"
      validation="required"
      validation-visibility="dirty"
      :classes="defaultFormikListClass"
    />

    <FormKit
      name="province"
      type="select"
      label="Provinsi"
      validation="required"
      placeholder="Pilih Provinsi"
      @input="onProvinceChange"
      :classes="defaultFormikListClass"
      :options="provinces"
    >
      <template #input="context">
        <div class="p-fluid">
          <Dropdown
            v-model="context.value"
            @change="(e) => {
              context.node.input(e.value);
            }"
            :options="context.options"
            optionLabel="label"
            optionValue="value"
            :placeholder="context.placeholder"
            :filter="true"
            filterPlaceholder="Cari Provinsi"
          >
            <template #option="optionContext">
              <option
                v-if="!optionContext.option.attrs"
                :key="optionContext.index"
                :value="optionContext.option.value"
              >
                {{ optionContext.option.label }}
              </option>
              <option v-else :key="optionContext.index" :disabled="true">
                Not Selected
              </option>
            </template>
          </Dropdown>
        </div>
      </template>
    </FormKit>

    <FormKit
      name="city"
      type="select"
      label="Kota"
      validation="required"
      placeholder="Pilih Kota"
      @input="onCityChange"
      :classes="defaultFormikListClass"
      :options="cities"
    >
      <template #input="context">
        <div class="p-fluid">
          <Dropdown
            v-model="context.value"
            @change="(e) => {
              context.node.input(e.value);
            }"
            :options="context.options"
            optionLabel="label"
            optionValue="value"
            :placeholder="context.placeholder"
            :filter="true"
            filterPlaceholder="Cari Kota"
            :disabled="Object.keys(cities).length === 0"
          >
            <template #option="optionContext">
              <option
                v-if="!optionContext.option.attrs"
                :key="optionContext.index"
                :value="optionContext.option.value"
              >
                {{ optionContext.option.label }}
              </option>
              <option v-else :key="optionContext.index" :disabled="true">
                Not Selected
              </option>
            </template>
          </Dropdown>
        </div>
      </template>
    </FormKit>

    <FormKit
      name="district"
      type="select"
      label="Kecamatan"
      validation="required"
      placeholder="Pilih Kecamatan"
      :classes="defaultFormikListClass"
      :options="districts"
    >
      <template #input="context">
        <div class="p-fluid">
          <Dropdown
            v-model="context.value"
            @change="(e) => {
              context.node.input(e.value);
            }"
            :options="context.options"
            optionLabel="label"
            optionValue="value"
            :placeholder="context.placeholder"
            :filter="true"
            filterPlaceholder="Cari Kecamatan"
            :disabled="Object.keys(districts).length === 0"
          >
            <template #option="optionContext">
              <option
                v-if="!optionContext.option.attrs"
                :key="optionContext.index"
                :value="optionContext.option.value"
              >
                {{ optionContext.option.label }}
              </option>
              <option v-else :key="optionContext.index" :disabled="true">
                Not Selected
              </option>
            </template>
          </Dropdown>
        </div>
      </template>
    </FormKit>

    <FormKit
      name="service_type"
      type="select"
      label="Jenis Layanan"
      validation="required"
      placeholder="Pilih Jenis Layanan"
      :classes="defaultFormikListClass"
      :options="serviceTypes"
    >
      <template #input="context">
        <div class="p-fluid">
          <Dropdown
            v-model="context.value"
            @change="(e) => {
              context.node.input(e.value);
            }"
            :options="context.options"
            optionLabel="label"
            optionValue="value"
            :placeholder="context.placeholder"
          >
            <template #option="optionContext">
              <option
                v-if="!optionContext.option.attrs"
                :key="optionContext.index"
                :value="optionContext.option.value"
              >
                {{ optionContext.option.label }}
              </option>
              <option v-else :key="optionContext.index" :disabled="true">
                Not Selected
              </option>
            </template>
          </Dropdown>
        </div>
      </template>
    </FormKit>

    <!-- <FormKit
      name="coverage_area"
      type="text"
      label="Coverage Area"
      validation="required|number"
      validation-visibility="dirty"
      :classes="defaultFormikListClass"
    /> -->

    <div>
      <label for="position">Lokasi</label>
      <GMapMap
        :center="center"
        :zoom="zoom"
        map-type-id="terrain"
        class="tw-mt-4 tw-w-full tw-relative"
        style="height: 500px"
        @click="onMarkerChange"
      >
        <GMapAutocomplete
          placeholder="Search..."
          @place_changed="setPlace"
          class="input-map"
        >
        </GMapAutocomplete>
        <GMapCluster>
          <GMapMarker
            :key="index"
            v-for="(m, index) in markers"
            :position="m.position"
            :clickable="true"
            :draggable="true"
            @click="updateCenter(m.position)"
          />
        </GMapCluster>
      </GMapMap>
    </div>
  </FormKit>

  <div class="p-fluid tw-mt-12 tw-mb-4">
    <Button label="Submit" @click="submitData" />
  </div>
</template>

<script setup>
import { defineEmits, defineProps, ref, watch } from "vue";
import { submitForm } from "@formkit/vue";

import { defaultFormikListClass } from "@/constants/formik";
import { useGeoLocation, useLocationDropdown } from "@/composables/location";
import { useSalonForm } from "@/composables/salon";

const props = defineProps({
  initialData: {
    type: Object,
    required: false,
  },
});
const initValue = ref(props.initialData);

const emit = defineEmits(["submit"]);

const { center, 
  lat, 
  lng, 
  updateCenter, 
  onMarkerChange, 
  initLatLng, 
  markers 
} = useGeoLocation();

const { provinces, 
  cities, 
  districts, 
  onProvinceChange, 
  onCityChange 
} = useLocationDropdown();

const { serviceTypes } = useSalonForm();

const zoom = ref(10);

const submit = (data) => {
  const formData = {
    ...data,
    province: Number(data.province),
    city: Number(data.city),
    district: Number(data.district),
    coverage_area: Number(data.coverage_area),
    service_type: Number(data.service_type),
    lat: lat.value,
    long: lng.value,
  };
  emit("submit", formData);
};

watch(
  () => props.initialData,
  (newData) => {
    initLatLng(newData.lat, newData.long);
    initValue.value = {
      province: String(newData.provinces_id),
      city: String(newData.cities_id),
      district: String(newData.districts_id),
      ...newData,
      service_type: String(newData.service_type),
    };

    updateCenter({lat: initValue.value.lat, lng: initValue.value.long});
    zoom.value = 13;
  }
);

const submitData = () => {
  submitForm("salons");
};

const setPlace = (event) => {
  initLatLng(event.geometry.location.lat(), event.geometry.location.lng());
  updateCenter(event.geometry.location);
  zoom.value = 15;
};
</script>
