<template>
  <div class="card">
    <h1 class="tw-text-xl tw-font-bold tw-mb-8">Create Salon</h1>
    <div>
      <Form @submit="submit" />
    </div>
  </div>
</template>

<script setup>
import Form from "./Form";
import {useSalonForm} from "@/composables/salon";
import {useRouter} from "vue-router";

const {create} = useSalonForm()
const router = useRouter()

const submit = async (data) => {
  await create(data)
  await router.push({name: 'salons'})

}
</script>